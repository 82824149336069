<template>
  <div class="appointment-picker" :class="[{ compact: compact }, 'ap-' + displayStyle]">
    <div v-show="currentView == 'calendar' || displayStyle == 'inline'" class="calendar">
      <div class="flex justify-center items-center gap-6">
        <div class="month-chevron" @click="prevMonth">
          <Icon type="chevron-left" fa-style="fas" />
        </div>
        <div class="flex gap-2 w-[120px] justify-center">
          <div>{{ dayjs(calendarMonthYear).format('MMMM') }}</div>
          <div>{{ dayjs(calendarMonthYear).format('YYYY') }}</div>
        </div>
        <div class="month-chevron" @click="nextMonth">
          <Icon type="chevron-right" fa-style="fas" />
        </div>
      </div>
      <div class="calendar-grid">
        <div v-for="dow in weekdays" :key="dow" class="calendar-grid-header">{{ dow }}</div>
        <div
          v-for="day in daysInMonth"
          :key="day"
          v-tippy="day.isClosed ? 'Closed' : ''"
          class="grid-day"
          :class="[
            {
              available: day.isAvailable,
              today: dayjs(day.date).isToday(),
              selected: day.day && dayjs(day.date).format('YYYY-MM-DD') == dayjs(selectedDate).format('YYYY-MM-DD')
            },
            day.trafficLight
          ]"
          @click="onDayClick(day)"
        >
          <!-- {{ day.isAvailable }} -->
          <div v-if="day.isClosed" class="grid-day-closed"></div>
          {{ day.day ? day.day : '' }}
        </div>
      </div>
    </div>

    <div v-show="currentView == 'time-picker' || (displayStyle == 'inline' && selectedTime)" class="time-slots">
      <div v-if="displayStyle != 'inline'" class="time-slot-back-btn" @click="changeView('calendar')">
        <Icon type="chevron-left" fa-style="fas" />
        <div>{{ dayjs(selectedDate).format('Do MMM YYYY') }}</div>
      </div>

      <div class="pb-4 flex justify-around">
        <div
          v-for="method in appointmentMethods"
          :key="method"
          v-tippy="method.available ? method.tippy : method.tippy + ' (unavailable)'"
          class="appointment-method-icon"
          :class="{ 'method-unavailable': !method.available }"
        >
          <Icon :type="method.available ? method.icon : method.icon + '-slash'" />
        </div>
      </div>

      <div class="time-slots-wrapper">
        <div
          v-for="slot in availableTimeSlots"
          :key="slot"
          class="time-slot"
          :class="{ selected: slot.start == selectedTime }"
          @click="onTimeSlotClick(slot)"
        >
          <div class="time" :class="{ disabled: !slot.isAvailable }">
            {{ dayjs('2000-01-01 ' + slot.start).format('h:mma') }}
          </div>
          <div v-if="slot.start == selectedTime" class="confirm-btn" @click="onConfirmClick(slot)">
            <Icon type="check" class="mr-1" />Confirm
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import Icon from '@/components/icon/Icon.vue'

import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
dayjs.extend(weekday)

import { computed } from 'vue'

export default {
  components: { Icon },
  props: {
    modelValue: {
      type: String,
      default: null
    },
    availableDates: {
      type: Array,
      default: () => []
    },
    dateFormat: {
      type: String,
      default: 'YYYY-MM-DD HH:mm:ss' // ISO 8601 = YYYY-MM-DD HH:mm:ss
    },
    showUnavailableTimes: {
      type: Boolean,
      default: true
    },
    trafficLight: {
      type: Boolean,
      default: true
    },
    displayStyle: {
      type: String,
      default: 'paged' // paged, inline
    },
    compact: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue', 'onSelect', 'dateRangeUpdated', 'onConfirm'],
  setup(props, { emit }) {
    const currentView = ref('calendar')

    const selectedDate = ref(null)
    const selectedTime = ref(null)
    const availableTimeSlots = ref([])

    const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

    const calendarMonthYear = ref(dayjs())

    function emitDateRangeChange() {
      emit('dateRangeUpdated', {
        start: dayjs(calendarMonthYear.value).startOf('month').format('YYYY-MM-DD'),
        end: dayjs(calendarMonthYear.value).endOf('month').format('YYYY-MM-DD')
      })
    }

    const daysInMonth = computed(() => {
      let days = []
      let startDay = dayjs(calendarMonthYear.value).startOf('month').day()
      let totalDays = dayjs(calendarMonthYear.value).daysInMonth()

      for (let i = 1; i < totalDays + startDay; i++) {
        let date = dayjs(dayjs(calendarMonthYear.value).format('YYYY-MM-') + (i - startDay + 1))
        let isAvailable = false
        let timeSlots = []
        let trafficLight = null
        let availableSlotCount = null

        let availabilityDate = props.availableDates.find(d => d.date === date.format('YYYY-MM-DD'))

        if (availabilityDate && !availabilityDate.availability.isClosed) {
          availableSlotCount = availabilityDate.availability.slotsAvailable

          if (availableSlotCount) {
            isAvailable = true
          }

          if (props.showUnavailableTimes) {
            timeSlots = availabilityDate.availability.timeSlots
          } else {
            timeSlots = availabilityDate.availability.timeSlots.filter(s => s.isAvailable)
          }
        }

        // calculate traffic light. calc: maxSlots = 100%, unavailableSlotsCount = x, x / 100 * 100
        if (availabilityDate?.availability.timeSlots?.length > 0 && props.trafficLight && date >= dayjs().subtract(1, 'days')) {
          if (availableSlotCount <= 0) {
            trafficLight = null
          } else if (availableSlotCount == 1) {
            trafficLight = 'danger'
          } else if (availableSlotCount == 2) {
            trafficLight = 'warning'
          } else {
            trafficLight = 'success'
          }
        }

        // loop through slots and set isAvailable to false if in the past
        timeSlots.forEach(slot => {
          if (dayjs(date.format('YYYY-MM-DD') + ' ' + slot.start) < dayjs()) {
            slot.isAvailable = false
          }
        })

        if (i >= startDay) {
          days.push({
            day: i - startDay + 1,
            date: date,
            isClosed: availabilityDate?.availability.isClosed ?? false,
            isAvailable: date >= dayjs().subtract(1, 'days') && isAvailable,
            maxSlots: availabilityDate?.availability.maxSlots ?? null,
            timeSlots: timeSlots,
            availableSlotCount: availableSlotCount,
            trafficLight: trafficLight,
            dataFetched: availabilityDate ? true : false
          })
        } else {
          // Date Padding
          days.push({
            day: null,
            date: null,
            isAvailable: false,
            timeSlots: []
          })
        }
      }
      return days
    })

    emitDateRangeChange()

    function nextMonth() {
      calendarMonthYear.value = dayjs(calendarMonthYear.value).add(1, 'month')
      emitDateRangeChange()
    }
    function prevMonth() {
      calendarMonthYear.value = dayjs(calendarMonthYear.value).subtract(1, 'month')
      emitDateRangeChange()
    }

    function changeView(view) {
      currentView.value = view
      selectedTime.value = props.modelValue
      emit('update:modelValue', props.modelValue)
    }

    function onDayClick(day) {
      if (day.isAvailable) {
        // console.log(day)
        selectedDate.value = day.date
        availableTimeSlots.value = day.timeSlots
        changeView('time-picker')
      }
    }

    function onTimeSlotClick(slot) {
      if (slot.isAvailable) {
        selectedTime.value = slot.start
      }
    }

    function onConfirmClick(slot) {
      emit('update:modelValue', dayjs(dayjs(selectedDate.value).format('YYYY-MM-DD') + ' ' + slot.start).format(props.dateFormat))
      emit('onConfirm')
      return
    }

    const appointmentMethods = computed(() => {
      let methods = [
        {
          method: 'physical',
          icon: 'map-marker',
          tippy: 'At Dealership',
          available: false
        },
        {
          method: 'video',
          icon: 'video',
          tippy: 'Video Call',
          available: false
        },
        {
          method: 'video_external',
          icon: 'video',
          tippy: 'Video Call',
          available: false
        },
        {
          method: 'phone',
          icon: 'phone',
          tippy: 'Phone Call',
          available: false
        }
      ]
      // get data using selectedDate on availableDates
      let availabilityDate = props.availableDates.find(d => d.date === dayjs(selectedDate.value).format('YYYY-MM-DD'))
      let availableMethods = availabilityDate?.availability?.appointmentMethods ?? []

      // set methods to available if in availableMethods
      methods.forEach(m => {
        if (availableMethods.includes(m.method)) {
          m.available = true
        }
      })

      // video and video_external are the same, so if either one of them is available, set video to available, and remove video_external from the array
      if (methods.find(m => m.method == 'video').available || methods.find(m => m.method == 'video_external').available) {
        methods.find(m => m.method == 'video').available = true
      }
      // Remove 'video_external' from methods array regardless of availability
      methods = methods.filter(m => m.method != 'video_external')
      console.log(methods)

      return methods
    })

    return {
      currentView,
      changeView,
      selectedDate,
      selectedTime,
      availableTimeSlots,
      onDayClick,
      onTimeSlotClick,
      onConfirmClick,
      weekdays,
      calendarMonthYear,
      daysInMonth,
      nextMonth,
      prevMonth,
      appointmentMethods
    }
  }
}

// IDEA
// Be able to define a default date, else today
// Be able to accept 2 arrays
// - Array of available dates
// - Array of unavailable dates. This should override the available dates

// Props for visuals
// - primary color
// - enableMonthYearDropdown

// Styling
// - Animated transition between months
// - visual feedback for time slot loading
</script>

<style scope>
.appointment-picker {
  user-select: none;
  max-width: max-content;
  overflow: hidden;
}
.appointment-picker.ap-paged {
  font-size: var(--text-lg);
}
.appointment-picker.ap-inline {
  font-size: var(--text-lg);
  display: flex;
  column-gap: var(--s-8);
}

.compact.ap-paged.appointment-picker {
  font-size: var(--text-md);
}

.calendar {
  max-width: max-content;
}

.month-chevron {
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--text-md);
  background-color: var(--extra-light);
}
.compact .month-chevron {
  height: 30px;
  width: 30px;
  font-size: var(--text-sm);
}
.month-chevron:hover {
  color: var(--primary);
  background-color: var(--light);
}
.month-chevron.disabled {
  color: var(--secondary);
  background-color: transparent;
  cursor: not-allowed;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: var(--s-2);
  text-align: center;
}
.compact .calendar-grid {
  gap: var(--s-1);
}

.calendar-grid-header {
  font-size: var(--text-sm);
  text-align: center;
  margin-top: var(--s-4);
  color: var(--secondary);
}

.grid-day {
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: var(--text-md);
  color: var(--secondary);
  position: relative;
}
.compact .grid-day {
  height: 30px;
  width: 30px;
  font-size: var(--text-sm);
}
.grid-day-closed::after {
  content: '';
  display: block;
  width: 60%;
  height: 1px; /* Adjust the thickness of the line */
  background-color: rgb(142, 142, 142); /* Color of the line */
  position: absolute;
  top: 50%; /* Adjust this to position the line correctly */
  left: 7px;
  transform: rotate(45deg); /* Rotates the line 45 degrees */
  transform-origin: center; /* Ensures the rotation is centered */
}
.grid-day.available {
  cursor: pointer;
  background-color: var(--primary-extra-light);
  color: var(--primary);
  font-weight: 600;
}
.grid-day.danger {
  background-color: var(--danger-extra-light);
  color: var(--danger);
}
.grid-day.warning {
  background-color: var(--warning-extra-light);
  color: var(--warning);
}
.grid-day.success {
  background-color: var(--success-extra-light);
  color: var(--success);
}
.grid-day.selected {
  background-color: var(--primary);
  color: white;
  font-weight: 600;
}
.grid-day.today::after {
  content: '';
  height: 4px;
  width: 4px;
  position: absolute;
  bottom: 5px;
  left: calc(50% - 2px);
  background-color: var(--secondary);
  border-radius: 4px;
}

.time-slots {
  min-width: 220px;
}
.compact .time-slots {
  min-width: 190px;
}

.appointment-method-icon {
  background-color: var(--light);
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--text-lg);
  position: relative;
}
.appointment-method-icon::after {
  content: '\f058';
  font-family: 'Font Awesome 6 Pro';
  font-weight: 900;
  right: -1px;
  bottom: -3px;
  position: absolute;
  color: var(--success);
}
.compact .appointment-method-icon {
  height: 30px;
  width: 30px;
  font-size: var(--text-sm);
}

.appointment-method-icon.method-unavailable {
  opacity: 0.4;
  cursor: not-allowed;
}
.appointment-method-icon.method-unavailable::after {
  content: '\e12e';
  font-family: 'Font Awesome 6 Pro';
  font-weight: 900;
  right: -1px;
  bottom: -3px;
  position: absolute;
  color: var(--danger);
}

.time-slot-back-btn {
  display: inline-flex;
  gap: var(--s-2);
  margin-bottom: var(--s-2);
  padding: var(--s-2) var(--s-4) var(--s-2) 0;
  cursor: pointer;
  color: var(--secondary);
}
.compact .time-slot-back-btn {
  padding: var(--s-1) var(--s-2) var(--s-1) 0;
  gap: var(--s-1);
}
.time-slot-back-btn:hover {
  color: var(--primary);
}

.time-slots-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--s-2);
  max-height: 300px;
  overflow: auto;
}
.compact .time-slots-wrapper {
  width: 190px;
  gap: var(--s-1);
}
.time-slot {
  overflow: hidden;
  white-space: nowrap;
  flex: 1 0;
}
.time-slot .time,
.time-slot .confirm-btn {
  color: var(--primary);
  box-sizing: border-box;
  border: 1px solid var(--primary);
  border-radius: var(--border-radius);
  height: 3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: var(--s-4) var(--s-4);
  white-space: nowrap;
  font-weight: 600;
}
.compact .time-slot .time,
.compact .time-slot .confirm-btn {
  height: 2.2rem;
  padding: var(--s-2) var(--s-4);
}

.time-slot .time {
  transition: all 0.3s;
  width: 100%;
}
.time-slot .confirm-btn {
  transition: all 0.3s;
  cursor: pointer;
}

.time-slot:not(.selected) .time {
  cursor: pointer;
}

.time-slot .time.disabled {
  cursor: not-allowed;
  text-decoration: none;
  background-color: var(--extra-light);
  border-color: transparent;
  color: var(--text-muted);
  text-decoration: line-through;
}
.time-slot:not(.selected).time:not(.disabled):hover {
  color: var(--primary);
  border: 2px solid var(--primary);
}
.time-slot.selected .time {
  width: calc(45% - 2px);
  border-color: var(--secondary);
  color: var(--secondary);
}
.time-slot.selected > .confirm-btn {
  background-color: var(--primary);
  color: white;
  margin-left: 4px;
  width: calc(55% - 2px);
}
</style>
