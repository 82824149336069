<template>
  <button
    class="btn"
    :class="[
      'btn-' + severity,
      'btn-' + size,
      'text-' + size,
      { 'btn-icon-only': icon && !label },
      { 'btn-rounded': rounded },
      { 'btn-outlined': outlined },
      { 'btn-link': link },
      { 'btn-text': text },
      { 'btn-text': plain },
      { 'btn-block': block }
    ]"
    :disabled="disabled || isLoading"
    @click="onClick($event)"
  >
    <span v-if="isLoading">
      <Icon type="spinner-third" class="btn-icon fa-spin" fa-style="far" />
    </span>
    <span v-else class="flex items-center" :class="[iconPos == 'right' ? 'flex-row-reverse' : '']">
      <Icon v-if="icon" fa-style="fas" class="btn-icon" :class="['btn-icon-' + iconPos]" :type="icon" />
      <span>{{ label }}</span>
    </span>

    <div v-if="isDropdown" class="btn-dropdown">
      <Icon type="chevron-down" fa-style="far" />
    </div>
  </button>
</template>

<script>
import Icon from '@/components/icon/Icon.vue'
export default {
  name: 'Button',
  components: {
    Icon
  },
  props: {
    severity: {
      type: String,
      default: 'primary'
    },
    label: {
      type: String,
      default: null
    },
    outlined: {
      type: Boolean,
      default: false
    },
    link: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean,
      default: false
    },
    plain: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'md'
    },
    rounded: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    iconPos: {
      type: String,
      default: 'left'
    },
    block: {
      type: Boolean,
      default: false
    },
    inTable: {
      type: Boolean,
      default: false
    },
    isDropdown: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const onClick = event => {
      const button = event.currentTarget
      const ripple = document.createElement('span')
      const diameter = Math.max(button.clientWidth, button.clientHeight)
      const radius = diameter / 2

      ripple.style.width = ripple.style.height = `${diameter}px`
      ripple.style.left = `${event.clientX - button.getBoundingClientRect().left - radius}px`
      ripple.style.top = `${event.clientY - button.getBoundingClientRect().top - radius}px`
      ripple.classList.add('btn-ripple')

      button.appendChild(ripple)

      setTimeout(() => {
        ripple.remove()
      }, 1000)
    }

    return { onClick }
  }
}
</script>

<style>
.btn-ripple {
  position: absolute;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  transform: scale(0);
  animation: ripple-animation 0.6s linear;
  pointer-events: none;
}

@keyframes ripple-animation {
  to {
    transform: scale(2);
    opacity: 0;
  }
}

.btn {
  color: white;

  text-align: center;
  border: 1px solid transparent;
  border-radius: var(--border-radius);
  font-weight: 500;

  position: relative;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  white-space: nowrap;
}

.btn-xs {
  padding: var(--s-1) var(--s-2);
  font-size: var(--text-xs);
}
.btn-sm {
  padding: var(--s-2) var(--s-4);
  font-size: var(--text-sm);
}
.btn-md {
  padding: var(--s-2) var(--s-4);
  font-size: var(--text-md);
}
.btn-lg {
  padding: var(--s-2) var(--s-5);
  font-size: var(--text-lg);
}
.btn-xl {
  padding: var(--s-3) var(--s-7);
  font-size: var(--text-xl);
}

.btn-rounded {
  border-radius: 100px;
}

.btn:not(.btn-icon) > .icon {
  margin-right: 5px;
}

/* .btn-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
} */

.btn-icon.btn-rounded {
  border-radius: 100px;
}

/* .btn-icon.btn-xs {
  padding: var(--s-1) var(--s-1);
}
.btn-icon.btn-sm {
  padding: var(--s-2) var(--s-2);
}
.btn-icon.btn-md {
  padding: var(--s-2) var(--s-2);
}
.btn-icon.btn-lg {
  padding: var(--s-2) var(--s-2);
}
.btn-icon.btn-xl {
  padding: var(--s-3) var(--s-3);
} */

.btn-primary {
  background-color: var(--primary);
}

.btn:hover {
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
}
.btn:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.btn-outline-primary {
  border-color: var(--primary);
  color: var(--primary);
}

.btn-outline-primary:hover {
  background-color: rgba(91, 192, 222, 0.1);
}
.dark .btn-primary:not(.btn-outlined, .btn-link, .btn-text) {
  background-color: var(--primary-dark-mode);
}

.btn-secondary {
  background-color: var(--secondary);
}

.btn-outline-secondary {
  border-color: var(--secondary);
  color: var(--secondary);
}

.btn-outline-secondary:hover {
  background-color: rgba(119, 140, 163, 0.1);
}

.btn-success {
  background-color: var(--success);
}

.btn-outline-success {
  border-color: var(--success);
  color: var(--success);
}

.btn-outline-success:hover {
  background-color: rgba(32, 191, 107, 0.1);
}

.btn-danger {
  background-color: var(--danger);
}

.btn-outline-danger {
  border-color: var(--danger);
  color: var(--danger);
}

.btn-outline-danger:hover {
  background-color: rgba(235, 59, 90, 0.1);
}

.btn-warning {
  background-color: var(--warning);
}

.btn-outline-warning {
  border-color: var(--warning);
  color: var(--warning);
}

.btn-outline-warning:hover {
  background-color: rgba(235, 59, 90, 0.1);
}

.btn-info {
  background-color: var(--info);
}

.btn-outline-info {
  border-color: var(--info);
  color: var(--info);
}

.btn-outline-info:hover {
  background-color: rgba(136, 84, 208, 0.1);
}

.btn-dark {
  background-color: var(--dark);
}

.btn-outline-dark {
  border-color: var(--dark);
  color: var(--dark);
}

.btn-outline-dark:hover {
  background-color: rgba(30, 35, 41, 0.1);
}

.btn-dropdown {
  margin-left: var(--s-2);
}

/* LINK */
.btn-link {
  background-color: transparent;
  color: var(--primary);
}
.btn-link:hover {
  text-decoration: underline;
}

/* TEXT */
.btn-text {
  background-color: transparent;
  border-color: transparent;
}
.btn-text.btn-primary {
  color: var(--primary);
}
.btn-text.btn-primary:enabled:hover {
  background-color: rgba(var(--primary-rgb), 0.15);
}
.btn-text.btn-secondary,
.btn-text.btn-plain {
  color: var(--secondary);
}
.btn-text.btn-secondary:enabled:hover,
.btn-text.btn-plain:enabled:hover {
  background-color: rgba(var(--secondary-rgb), 0.15);
}
.btn-text.btn-success {
  color: var(--success);
}
.btn-text.btn-success:enabled:hover {
  background-color: rgba(var(--success-rgb), 0.15);
}
.btn-text.btn-warning {
  color: var(--warning);
}
.btn-text.btn-warning:enabled:hover {
  background-color: rgba(var(--warning-rgb), 0.15);
}
.btn-text.btn-danger {
  color: var(--danger);
}
.btn-text.btn-danger:enabled:hover {
  background-color: rgba(var(--danger-rgb), 0.15);
}
.btn-text.btn-info {
  color: var(--info);
}
.btn-text.btn-info:enabled:hover {
  background-color: rgba(var(--info-rgb), 0.15);
}

/* OUTLINED */
.btn-outlined {
  background-color: transparent;
  border: 1px solid;
}
.btn-outlined.btn-primary {
  color: var(--primary);
}
.btn-outlined.btn-secondary {
  color: var(--secondary);
}
.btn-outlined.btn-success {
  color: var(--success);
}
.btn-outlined.btn-warning {
  color: var(--warning);
}
.btn-outlined.btn-danger {
  color: var(--danger);
}
.btn-outlined.btn-info {
  color: var(--info);
}

/* ROUNDED */
.btn-rounded {
  border-radius: 100px;
}

/* ICON  */
.btn-icon-only {
  /* padding-left: 0;
  padding-right: 0; */
  /* width: 2.5rem; */
  flex-shrink: 0;
}
.btn-icon-only.btn-xs {
  width: 1.8rem;
}
.btn-icon-only.btn-sm {
  width: 2.2rem;
}
.btn-icon-only.btn-md {
  width: 2.4rem;
}
.btn-icon-only.btn-lg {
  width: 2.6rem;
}
.btn-icon-only.btn-xl {
  width: 3.1rem;
}

.btn-icon-left {
  margin-right: var(--s-2);
}
.btn-icon-right {
  margin-left: var(--s-2);
}

.btn-icon-only .btn-icon {
  margin: 0;
}

/* BLOCK */
.btn-block {
  width: 100%;
}
</style>
