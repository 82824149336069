<template>
  <div class="icon">
    <i
      :class="[
        { 'fa-spin': spinner, 'fa-pulse': pulse },
        faStyle,
        'fa-fw fa-' + iconType,
      ]"
      :style="'color: var(--' + iconStyle + ')!important'"
    ></i>
  </div>
</template>

<script>
import { watch, ref } from "vue";

export default {
  name: "Icon",
  components: {},
  props: {
    type: {
      type: String,
      default: null,
    },
    iconStyle: {
      type: String,
      default: "",
    },
    badgeContent: {
      type: String,
      default: "",
    },
    spinner: {
      type: Boolean,
      default: false,
    },
    pulse: {
      type: Boolean,
      default: false,
    },
    faStyle: {
      type: String,
      default: "fad",
    },
    badgeSize: {
      type: String,
      default: "md",
    },
  },
  setup(props) {
    const iconType = ref(props.type);

    // Watch for changes to type
    watch(
      () => props.type,
      (newVal) => {
        if (newVal) {
          iconType.value = newVal;
        }
      },
      { immediate: true }
    );

    return { iconType };
  },
};
</script>

<style>
.icon {
  display: inline-flex;
  position: relative;
}
</style>
