<template>
  <div class="boxed select-none" :class="{ 'has-error': hasError }">
    <div
      v-for="option in options"
      :key="option.value"
      v-tippy="option.disabled ? 'Unavailable' : ''"
      :for="option.value"
      class="option"
      :class="[{ active: option.value == modelValue, disabled: option.disabled }, option.classes]"
      @click="!option.disabled && optionClicked(option)"
    >
      <Icon v-if="option.icon" :type="option.icon" />
      <div class="label">{{ option.label }}</div>
      <div v-if="option.description" class="description">{{ option.description }}</div>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/icon/Icon.vue'
export default {
  components: {
    Icon
  },
  props: {
    modelValue: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      default: null
    },
    selectedOption: {
      type: String,
      default: null
    },
    hasError: {
      type: Boolean,
      default: false
    }
  },
  emits: ['optionChange', 'update:modelValue'],
  setup(props, { emit }) {
    function optionClicked(option) {
      emit('optionChange', option.value)
      emit('update:modelValue', option.value)
    }

    return { optionClicked }
  }
}
</script>

<style scoped>
.boxed {
  display: flex;
  gap: var(--s-4);
  overflow: hidden; /* To prevent content spillover */
}
.boxed > .option {
  padding: 10px;
  border-radius: calc(var(--border-radius) / 2);
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--input-border-color);
}
.boxed > .option > .icon {
  font-size: 1.5rem;
}
.boxed > .option > .label {
  font-weight: 500;
}
.boxed > .option > .description {
  font-size: var(--text-xs);
  color: var(--text-secondary);
  text-align: center;
  text-wrap: balance;
}
.option.active {
  background-color: white;
  border-color: var(--primary);
}
.option.active > .label {
  color: var(--primary);
}
.option.active > .icon {
  color: var(--primary);
}
.option.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.boxed.has-error > .option {
  color: var(--danger);
  border-color: var(--danger);
}

/*
.dark .option.active {
  background-color: var(--darkmode-3);
} */
</style>
